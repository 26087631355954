html,body {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
		margin: 0;
}

#root {
    height:100%;
		width: 100%;
		padding: 0;
}
