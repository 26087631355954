@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@500&display=swap');
.Tabs {
    font-family: 'EB Garamond', serif;
    font-size: 30px;
    color: #70CDA3;
}

@media only screen and (min-width:62em){
    .Tabs {
        transform: rotate(90deg);
    }
}
