@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@500&display=swap');

.HomePageContent {
    font-family: 'EB Garamond', serif;
    font-size: 3vh;
    color: #70CDA3;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.content {
    display: flex;
    justify-content: center;
}

.OpenTags {
    color: #70CDA3;
    font-size: 0.8em;
}

