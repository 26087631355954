.container {
    width:90vw;
    display: flex;
    justify-content: center;
}

.form_container {
    width: 100%;
    height: 85vh;
    display: flex;
    justify-content: center;
}