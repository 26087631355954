@keyframes fillcolor {
    0% {
        color: #70CDA3;
        fill: #ffffff;
    }

    100% {
        color: #70CDA3;
        fill: #48BB78;
    }
}

.Container>* {
    color: #70CDA3;
}

.Container>*:hover {
    animation: fillcolor 0.1s ease-in;
    animation-fill-mode: forwards;
}
